import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import BlogCategories from "../../components/blog-categories";
import Feadback from "../../components/forms/feedback";
import Subscribe from "../../components/forms/subscribe";
import { ICategorySummary } from "../../components/blogs";

const ThemePage = ({ data }) => {
  const {
    allContentfulBlogPost: { categories, totalBlogPostsByCategory },
  } = data;
  const categoriesSummary: ICategorySummary = {
    totalBlogPostsByCategory,
    totalBlogPosts: NaN,
  };

  return (
    <Layout>
      <section className="page section-center about-page">
        <div>
          <BlogCategories
            categories={categories}
            show
            rootPath="/blog/"
            hideAll
            categoriesSummary={categoriesSummary}
          />
          <div style={{ margin: "0px auto", maxWidth: "700px" }}>
            <Feadback sectionTitle={"Faites-nous part de vos commentaires"} />{" "}
          </div>
        </div>
      </section>
      <Subscribe />
    </Layout>
  );
};

export default ThemePage;

export const query = graphql`
  {
    allContentfulBlogPost {
      categories: distinct(field: categories)
      totalBlogPostsByCategory: group(field: categories) {
        totalCount
        category: fieldValue
      }
    }
  }
`;
